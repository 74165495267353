import TagManager from "react-gtm-module";
import AppConfig from "../App.config";

const isDev = process.env.NODE_ENV === "development";
//const isDev = process.env.NODE_ENV || process.env.NODE_ENV === "development";
const GTM_TRACKING_ID = AppConfig.GOOGLE.GTM_TRACKING_CODE || "";

const logDebug = (...args) => {
  if (isDev) console.debug(args);
};

const initGTM = () => {
  // Enable debug mode on the local development environment
  logDebug("GTM init", GTM_TRACKING_ID, isDev);

  const tagManagerArgs = {
    gtmId: GTM_TRACKING_ID,
  };

  TagManager.initialize(tagManagerArgs);
};

const logEvent = (category = "", action = {}) => {
  logDebug(`GTM logging event`, category, action);
};

const logPageView = (url) => {
  logDebug(`GTM logging pageview for ${url}`, GTM_TRACKING_ID, isDev);

  /*  dataLayer: {
    event: 'pageview',
    pagePath: 'page-path-here',
    pageTitle: 'page-title-here',
  },*/
  //const dataLayer = window.dataLayer || [];
  //console.log(dataLayer);

  TagManager.dataLayer({
    dataLayer: {
      event: "page_view",
      pagePath: url,
    },
  });
};

export { initGTM, logEvent, logPageView };
