import AOS from "aos";
import "aos/dist/aos.css";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect } from "react";
import Footer from "./components/layout/Footer";
import TopMenu from "./components/layout/TopMenu";
import ScrollToTop from "./components/utils/ScrollHandler/ScrollToTop";
import "./css/styles.css";
import Router from "./Router";
import AppAnalytics from "./SEO/AppAnalytics";
import { AppMeta } from "./SEO/AppMeta";

// const isSnap = navigator.userAgent !== "ReactSnap";

const App = () => {
  // let locationPath = useLocation();
  // console.log(locationPath);
  // console.log(isSnap);

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <div className="App">
      <AppMeta />
      <AppAnalytics>
        <ScrollToTop spy={true} smooth={true} duration={500} AOS={AOS} />
        <TopMenu />
        <React.StrictMode>
          <Router />
        </React.StrictMode>
        <Footer />
      </AppAnalytics>
    </div>
  );
};

export default App;
