import { IoMdPlay } from "@react-icons/all-files/io/IoMdPlay";
import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link as LinkScroll } from "react-scroll";
import { dataСourses } from "../../../../../data/courses";
import { Context } from "../../../../../SEO/AnalyticsProvider";
import { cnj } from "../../../../utils/Utils";
import VideoModal from "../../../../utils/VideoModal/VideoModal";
import СourseCarousel from "../../../TypicalSections/Utils/СourseCarousel";
import { dataSectionHero as data } from "../data/course";

const VideoURL = process.env.REACT_APP_VIDEO_COURSE1_URL;

const SectionHero = (props) => {
  // eslint-disable-next-line no-unused-vars
  const { name, isCoursesCorusel } = props;

  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  const { triggerYM } = useContext(Context);

  useEffect(() => {
    // Event("PRODUCT", "Product added to cart", "PRODUCT_PAGE")
    //const action = `Show/hide hero video: ${isOpen}`;
    //logEvent("USER_EVENT", action);
    if (isOpen) triggerYM("click", "Show video");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  // console.log(VideoURL);

  return (
    <header
      className={cnj(
        "section-hero banner-item-bg-1 d-flex align-items-center",
        `section-hero-${name}`
      )}
      name={`section-hero-${name}`}
      id="hero"
    >
      <Container>
        <Row className="">
          <Col lg="7" className="text-center text-md-start">
            <div className="banner-text">
              <h2>{data.subtitle}</h2>
              <h1>{data.title}</h1>
              <h3>{data.description}</h3>
              <div className="banner-btn text-center">
                <LinkScroll
                  activeClass="active"
                  className="btn btn-new"
                  to="section-course1-price"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={700}
                  onClick={() => triggerYM("click", "Scroll down to contacts")}
                >
                  {data.buttons[0].title}
                </LinkScroll>
              </div>
            </div>
          </Col>
          <Col lg="5" className="hero-video-btn text-center">
            <div className="video-btn-animat">
              <div className="video-btn popup-youtube">
                <i onClick={openModal}>
                  <IoMdPlay />
                </i>
              </div>
            </div>
            {isCoursesCorusel && (
              <div className="d-none d-lg-block banner-carousel text-center">
                <h2>{data.courseTitle}</h2>
                <СourseCarousel data={dataСourses} />
              </div>
            )}
          </Col>
        </Row>
      </Container>
      <div className="lines">
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
      </div>
      <VideoModal
        isOpen={isOpen}
        handlerCloseModal={closeModal}
        videoURL={VideoURL}
      />
    </header>
  );
};

export default SectionHero;
