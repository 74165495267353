export const dataOwner = {
  title: "ИП Ловков Александр Вячеславович",
  fullName: "Ловков Александр Вячеславович",
  inn: "781011469822",
  kpp: "", // КПП
  ogrybp: "316784700344301", // ОГРНИП
  orgn: "316784700344301", // ОГРН
  address: "170043, Тверь, 3-й пр-д Чкалова, 7", // Адрес
  account: "40802 810 8291 6000 0155", // Реквизиты расчетного счета
  accountCorr: "30101 810 2000 0000 0824", // Кор. счёт
  bank: "ОАО «АЛЬФА_БАНК»",
  bankBik: "042202824", //  БИК: 042202824
  bankKpp: "", //  КПП:
  email: "lovkova@mail.ru", // Email
  phone: "+7(926)785-5245",
};

export const dataSocialLinks = {
  facebook: "https://fb.me/itdefenseschool/",
  twitter: "https://twitter.com/",
  instagram: "https://www.instagram.com/itsecurityschool/",
  telegram: "https://www.telegram.com/",
  youtube: "https://www.youtube.com/",
};
