import React from "react";
import { Modal } from "react-bootstrap";

export const VideoPlayerIFrame = (props) => {
  const { videoURL } = props;

  return (
    <iframe
      width="560"
      height="315"
      title="Campus Tour"
      aria-hidden={true}
      src={videoURL}
      frameBorder="0"
      allowFullScreen
      allow={
        "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picturse"
      }
    />
  );
};

export const VideoPlayer = (props) => {
  const { videoURL } = props;

  return (
    <object width="100%" height="450px">
      <param name="movie" value={videoURL} />
      <embed src={videoURL} width="100%" height="450px" />
    </object>
  );
};

const VideoModal = (props) => {
  const { isOpen, handlerCloseModal, videoURL } = props;

  const URL = videoURL || "https://youtube.com/embed/YE7VzlLtp-4";

  if (!isOpen) {
    return null;
  }

  return (
    <Modal centered show={isOpen} onHide={handlerCloseModal} size="lg">
      <div className="video-modal">
        <div className="modal-video-body">
          <div className="modal-video-inner">
            <div className="modal-video-movie-wrap">
              <button
                className="modal-video-close-btn"
                onClick={handlerCloseModal}
                aria-label="Close the modal by clicking here"
              />
              {props.children || <VideoPlayer videoURL={URL} />}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default VideoModal;

/*
    <Fragment>
      {() => {
        if (!isOpen) {
          return null;
        }
        return (
          <Modal centered show={isOpen} onHide={handlerCloseModal}>
            <div className="video-modal">
              <div className="modal-video-body">
                <div className="modal-video-inner">
                  <div className="modal-video-movie-wrap">
                    <button
                      className="modal-video-close-btn"
                      onClick={handlerCloseModal}
                      aria-label="Close the modal by clicking here"
                    />
                    <VideoPlayer videoURL={videoURL} />
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        );
      }}
    </Fragment>
*/
