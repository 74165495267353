import PropTypes from "prop-types";
import React from "react";
import { Col, Row } from "react-bootstrap";
import PageSection from "../../../utils/SiteSection/PageSection";
import { cnj } from "../../../utils/Utils";
import CountUp from "./CountUp";

const Section = (props) => {
  const { data, courseName, cn, name } = props;

  return (
    <PageSection
      courseName={courseName}
      name={name || "countup"}
      cn={cnj("countup", cn && `${cn}`)}
      data={data}
    >
      <Row>
        {data.details.map((item, index) => {
          return (
            <Col key={index} lg={3} md={6} sm={6}>
              <CountUp data={item} />
            </Col>
          );
        })}
      </Row>
    </PageSection>
  );
};

Section.propTypes = {
  courseName: PropTypes.string.isRequired,
};

export default Section;
