import React from "react";
import { Helmet } from "react-helmet-async";
import { metaCommonKeywords } from "../data/SEO/keywords";

// const isDev = process.env.NODE_ENV === "development";

export const PageMeta = ({ metaPage, keywords }) => {
  // console.log("isDev: ", isDev, process.env.NODE_ENV.trim() === "development");
  // console.log("isDev: ", isDev, process.env.NODE_ENV === "development");

  const pageKeywords =
    keywords && keywords.lenght > 0
      ? [...metaPage.keywords, ...keywords]
      : metaPage.keywords;

  const pageKeywordsCommon =
    metaCommonKeywords && metaCommonKeywords.lenght > 0
      ? [...pageKeywords, ...metaCommonKeywords]
      : pageKeywords;

  const getMetaPropertyName = (property) => {
    if (property === "imageWidth") return "image:width";
    else if (property === "imageHeight") return "image:height";
    else return property;
  };

  const ORG_SCHEMA = JSON.stringify(metaPage.jsonLd);
  // console.log(metaPage.jsonLd);

  return (
    <Helmet>
      <title>{metaPage.title}</title>
      <meta name="description" content={metaPage.description} />
      <meta name="keywords" content={pageKeywordsCommon.join(",")} />
      <meta name="author" content={metaPage.author} />
      {/* canonical */}
      {metaPage?.canonical && (
        <link rel="canonical" href={metaPage.canonical} />
      )}
      {/* inline script elements */}
      {metaPage.robots && <meta name="robots" content={metaPage.robots} />}
      {metaPage.prerenderStatusVode && (
        <meta
          name="prerender-status-code"
          content={metaPage.prerenderStatusVode}
        />
      )}
      {/* inline script elements */}
      {metaPage.og &&
        Object.entries(metaPage.og).map(([property, content], index) => (
          <meta
            property={`og:${getMetaPropertyName(property)}`}
            content={content}
            key={index}
          />
        ))}
      {/* inline script elements */}
      {metaPage.article &&
        Object.entries(metaPage.article).map(([property, content], index) => (
          <meta
            property={`article:${getMetaPropertyName(property)}`}
            content={content}
            key={index}
          />
        ))}
      {/* inline script elements */}
      <meta property="fb:app_id" content="257953674358265" />
      {/* inline script elements */}
      <meta name="format-detection" content="telephone=no" />
      {/* ld+json */}
      {metaPage.jsonLd && (
        <script type="application/ld+json" key={metaPage.jsonLdId}>
          {ORG_SCHEMA}
        </script>
      )}
    </Helmet>
  );
};
