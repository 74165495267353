import PropTypes from "prop-types";
import React from "react";
import { SectionCountUp } from "../../../TypicalSections/CountUp";
import { dataCountUp as data } from "../data/course";

const Section = (props) => {
  const { courseName, cn, name } = props;

  return (
    <SectionCountUp courseName={courseName} name={name} cn={cn} data={data} />
  );
};

Section.propTypes = {
  courseName: PropTypes.string.isRequired,
};

export default Section;
