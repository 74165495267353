import React from "react";
import { Col, Figure, Row } from "react-bootstrap";
import { cnj } from "../../../utils/Utils";

const ThreeColumnContent = (props) => {
  const { cn, name, data } = props;

  return (
    <Row className={cnj("text-sm-center", cn && `${cn}`)}>
      <Col lg="4" sm="6">
        <div
          className={cnj(
            "content-inner-item",
            `content-inner-item-${name}`,
            cn && `${cn}-inner-item`
          )}
        >
          <div className="content-body">
            <h3 className="content-title">
              <Figure className="content-title-icon d-block text-center">
                <Figure.Image src={data[0].image} height="64" width="64" />
              </Figure>
              {data[0].title}
            </h3>
            <p className="content-text">{data[0].description}</p>
          </div>
        </div>
      </Col>
      <Col lg="4" sm="6">
        <div
          className={cnj(
            "content-inner-item",
            `content-inner-item-${name}`,
            cn && `${cn}-inner-item`
          )}
        >
          <div className="content-body">
            <h3 className="content-title">
              <Figure className="content-title-icon d-block text-center">
                <Figure.Image src={data[1].image} height="64" width="64" />
              </Figure>
              {data[1].title}
            </h3>
            <p className="content-text">{data[1].description}</p>
          </div>
        </div>
      </Col>
      <Col lg="4" sm="6" className="offset-sm-3 offset-lg-0">
        <div
          className={cnj(
            "content-inner-item",
            `content-inner-item-${name}`,
            cn && `${cn}-inner-item`
          )}
        >
          <div className="content-body">
            <h3 className="content-title">
              <Figure className="content-title-icon d-block text-center">
                <Figure.Image src={data[2].image} height="64" width="64" />
              </Figure>
              {data[2].title}
            </h3>
            <p className="content-text">{data[2].description}</p>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default ThreeColumnContent;
