import React from "react";
import AppConfig from "../App.config";
import AnalyticsProvider from "./AnalyticsProvider";

const YM_TRACKING_ID = Number(AppConfig.YANDEX.YM_TRACKING_CODE) || 0;
const GA_TRACKING_ID = AppConfig.GOOGLE.GA_TRACKING_CODE || "";
const GTM_TRACKING_ID = AppConfig.GOOGLE.GTM_TRACKING_CODE || "";
// const isGA4 = GA_TRACKING_ID.indexOf("UA-") === -1;

const analyticsConfig = {
  yandexMetrika: YM_TRACKING_ID,
  googleAnalytics: GA_TRACKING_ID,
  googleTags: GTM_TRACKING_ID,
};

const AppAnalytics = ({ children }) => {
  return <AnalyticsProvider {...analyticsConfig}>{children}</AnalyticsProvider>;
};

export default AppAnalytics;
