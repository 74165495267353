import PropTypes from "prop-types";
import React from "react";
import { Container } from "react-bootstrap";
import Image1 from "../../../../../images/Course1/image2.png";
import SiteSection from "../../../../utils/SiteSection/SiteSection";
import SectionTitle from "../../../../utils/SiteSection/Title/SiteSectionTitle";
import Section6 from "./Section";

const data = {
  title: "",
  subtitle: "",
  description: "",
  details: {
    title: "Инсценеровка проникновения в ИТ-инфраструктуру (PENTEST)",
    subtitle: "",
    description:
      "Вы объединяетесь в команды и будете получать баллы за каждое выполненное задание.\nУ Вас будет уникальная возможность смоделировать действий злоумышленника при совершении атаки на информационные системы и их дальнейшей эксплуатации.",
    image: Image1,
    alt: "",
    detailsTitle: "Такой формат позволяет",
    details: [
      "Сделать из курса не просто набор полезных видео, а ваш будущий результат",
      "Получить навык выявленных уязвимостей и построению системы защиты",
    ],
  },
};

const SectionTwo = (props) => {
  const { name } = props;

  return (
    <SiteSection name={`${name}-section6`} className="content">
      <Container>
        <SectionTitle
          title={data.title}
          description={data.description}
          data-aos="fade-up"
        />
      </Container>
      <Section6 name={`${name}-section6`} data={data.details} />
    </SiteSection>
  );
};

SectionTwo.propTypes = {
  name: PropTypes.string.isRequired,
};

export default SectionTwo;
