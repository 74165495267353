import { RiCheckDoubleLine } from "@react-icons/all-files/ri/RiCheckDoubleLine";
import React from "react";
import { Accordion, Col, Row } from "react-bootstrap";
import PageSection from "../../../utils/SiteSection/PageSection";
import { cnj } from "../../../utils/Utils";

const CourseProgram = (props) => {
  const { data, courseName, cn, name } = props;

  const ListItems = (props) => {
    const { data, className } = props;
    const ListItems = data.map((item, index) => (
      <li className="content-list-group-item" key={index}>
        <RiCheckDoubleLine />
        {item}
      </li>
    ));
    return (
      <ul
        className={cnj("content-list-group", className && `${className}-list`)}
      >
        {data && ListItems}
      </ul>
    );
  };

  return (
    <PageSection
      courseName={courseName}
      name={name || "program"}
      cn={cnj("course-program", cn && `${cn}`)}
      data={data}
    >
      <Row>
        <Col>
          <Accordion defaultActiveKey="0" flush>
            {data.details &&
              data.details.map((item, index) => {
                return (
                  <Accordion.Item eventKey={index} key={index}>
                    <Accordion.Header>{item.title}</Accordion.Header>
                    <Accordion.Body>
                      {item.description && <p> {item.description}</p>}
                      {item.details && <ListItems data={item.details} />}
                    </Accordion.Body>
                  </Accordion.Item>
                );
              })}
          </Accordion>
        </Col>
      </Row>
    </PageSection>
  );
};

export default CourseProgram;

/*
    <SiteSection name={name} className="course-program">
      <Container>
        <SectionTitle
          title={data.title}
          description={data.description}
          data-aos="fade-up"
        />
        <Row>
          <Col>
            <Accordion defaultActiveKey="0" flush>
              {data.details &&
                data.details.map((item, index) => {
                  return (
                    <Accordion.Item eventKey={index} key={index}>
                      <Accordion.Header>{item.title}</Accordion.Header>
                      <Accordion.Body>
                        {item.description && <p> {item.description}</p>}
                        {item.details && <ListItems data={item.details} />}
                      </Accordion.Body>
                    </Accordion.Item>
                  );
                })}
            </Accordion>
          </Col>
        </Row>
      </Container>
    </SiteSection>
*/
