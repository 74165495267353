export const metaAuthor =
  "ITSecuritySchool.ru - Онлайн-школа кибербезопасности и программирования";

export const metaPageHome = {
  title: "Онлайн-школа программирования и кибербезопасности ITSecuritySchool",
  description:
    "IT-Security School онлайн-школы кибербезопасности и программирования, ежедневно подбирая самые актуальные материалы из мира кибербезопасности и программирования мы помогаем вам расти и развиваться | Качественное образование - доступное каждому | Стань востребованным специалист по информационной безопасности уже завтра",
  author: metaAuthor,
  url: "https://itsecurityschool.ru/home",
  // canonical: "https://itsecurityschool.ru",
  keywords: [],
  robots: "index, follow",
  jsonLdId: "97c9977f-fec0-4b83-af40-082b4b26cda3",
  jsonLd: {
    "@context": "https://schema.org/",
    "@type": "Course",
    name: "Курс «Этичный хакер»",
    description:
      "Основы кибербезопасности и тестирование на проникновение (пентест) и этичный взлом",
    url: "https://itsecurityschool.ru",
    logo: "https://itsecurityschool.ru/images/logo.png",
    provider: {
      "@type": "Organization",
      name: "ITSecuritySchool.ru - Онлайн-школа кибербезопасности и программирования",
      sameAs: "https://itsecurityschool.ru",
    },
  },
};

export const metaPageBlog = {
  title: "Блог о кибербезопасности и программирование IT-Security School",
  description:
    "IT-Security School онлайн-школы кибербезопасности и программирования, ежедневно подбирая самые актуальные материалы из мира кибербезопасности и программирования мы помогаем вам расти и развиваться | Cайт содержатся материалы для специалистов по тестированиии на проникновение и разработчиков | Запишитесь на обучение сейчас",
  author: metaAuthor,
  url: "https://itsecurityschool.ru/blog",
  // canonical: "https://itsecurityschool.ru/blog",
  keywords: [],
  robots:
    "index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1",
  og: {
    locale: "ru_RU",
    type: "website",
    title: "Блог онлайн-школы IT-Security School",
    description:
      "Блог онлайн-школы IT-Security School — отличный способ получать самую нужную и проверенную информацию в мире IT и кибербезопасности.",
    url: "https://itsecurityschool.ru/blog",
    image:
      "https://itsecurityschool.ru/images/thumbs/main_img_internet-1918x848.e5d07fda.jpg",
    imageWidth: "190",
    imageHeight: "190",
  },
};

export const metaPageContact = {
  title:
    "Контакты онлайн-школы кибербезопасности и программирования IT-Security School",
  description:
    "Контакты онлайн-школы кибербезопасности и программирования IT-Security School | Качественное образование - доступное каждому | Запишитесь на обучение сейчас",
  author: metaAuthor,
  url: "https://itsecurityschool.ru/contact",
  // canonical: "https://itsecurityschool.ru/contact",
  keywords: [],
  robots: "index, follow",
  og: {
    locale: "ru_RU",
    type: "website",
    title: "Контакты",
    description: "",
    url: "https://itsecurityschool.ru/contact",
    image: null,
    imageWidth: "190",
    imageHeight: "190",
  },
};

export const metaPageSecurityClub = {
  title:
    "Закрытый клуб онлайн-школы кибербезопасности и программирования IT-Security School",
  description:
    "Закрытый клуб онлайн-школы кибербезопасности и программирования IT-Security School | Качественное образование - доступное каждому | Запишитесь на обучение сейчас",
  author: metaAuthor,
  url: "https://itsecurityschool.ru/securityclub",
  // canonical: "https://itsecurityschool.ru/securityclub",
  keywords: [],
  robots: "index, follow",
  og: {
    locale: "ru_RU",
    type: "website",
    title: "Закрытый клуб",
    description: "",
    url: "https://itsecurityschool.ru/securityclub",
    image: null,
    imageWidth: "190",
    imageHeight: "190",
  },
};

export const metaPageOfferContract = {
  title: "Договор-оферта (пользовательское соглашение)",
  description:
    "Публичный договор-оферта (пользовательское соглашение) на оказание информационных услуг | Онлайн-школа кибербезопасности и программирования IT-Security School | Запишитесь на обучение онлайн",
  author: metaAuthor,
  url: "https://itsecurityschool.ru/offercontract",
  // canonical: "https://itsecurityschool.ru/offercontract",
  keywords: [],
  robots: "noindex, nofollow",
  og: {
    locale: "ru_RU",
    type: "website",
    title: "Договор-оферта",
    description: "",
    url: "https://itsecurityschool.ru/offercontract",
    image: null,
    imageWidth: "190",
    imageHeight: "190",
  },
};

export const metaPagePrivacyPolicy = {
  title: "Политика конфиденциальности",
  description:
    "Политика конфиденциальности | Онлайн-школа кибербезопасности и программирования IT-Security School | Запишитесь на обучение онлайн",
  author: metaAuthor,
  url: "https://itsecurityschool.ru/privacy",
  // canonical: "https://itsecurityschool.ru/privacy",
  keywords: [],
  og: {
    locale: "ru_RU",
    type: "website",
    title: "Политика конфиденциальности",
    description: "",
    url: "https://itsecurityschool.ru/privacy",
    image: null,
    imageWidth: "190",
    imageHeight: "190",
  },
};

export const metaPageEventRegistration = {
  title: "Благодарим за регистрацию",
  description:
    "Благодарим за регистрацию | Качественное образование - доступное каждому | Онлайн-школа кибербезопасности и программирования IT-Security School",
  author: metaAuthor,
  url: "https://itsecurityschool.ru/event/registration",
  // canonical: "https://itsecurityschool.ru/event/registration",
  keywords: [],
  robots: "noindex, nofollow",
  og: {
    locale: "ru_RU",
    type: "website",
    title: "Блог онлайн-школы IT-Security School",
    description:
      "Блог онлайн-школы IT-Security School — отличный способ получать самую нужную и проверенную информацию в мире IT и кибербезопасности.",
    url: "https://itsecurityschool.ru/event/registration",
    image:
      "https://itsecurityschool.ru/images/thumbs/main_img_internet-1918x848.e5d07fda.jpg",
    imageWidth: "190",
    imageHeight: "190",
  },
};

export const metaPageGlossary = {
  title:
    "Глоссарий | Онлайн-школа кибербезопасности и программирования IT-Security School",
  description:
    "Глоссарий | Качественное образование - доступное каждому | Онлайн-школа кибербезопасности и программирования IT-Security School | Запишитесь на обучение онлайн",
  author: metaAuthor,
  url: "https://itsecurityschool.ru/glossary",
  // canonical: "https://itsecurityschool.ru/glossary",
  keywords: [],
  og: {
    locale: "ru_RU",
    type: "website",
    title: "Глоссарий",
    description: "",
    url: "https://itsecurityschool.ru/glossary",
    image: null,
    imageWidth: "190",
    imageHeight: "190",
  },
};

/* <meta property="og:title" content="Awesome app - the best app ever" />
<meta property="og:type" content="website" />
  <script type="application/ld+json">{`
  {
      "@context": "http://schema.org",
      "@type": "Organization",
      "url": "https://itsecurityschool.ru",
      "logo": "https://itsecurityschool.ru/images/logo.png",
  }
`}</script>; */

export const metaSite = {
  title: "Онлайн-школа программирования и кибербезопасности IT-Security School",
  description:
    "IT-Security School онлайн-школы кибербезопасности и программирования, ежедневно подбирая самые актуальные материалы из мира кибербезопасности и программирования мы помогаем вам расти и развиваться | Качественное образование - доступное каждому | Запишитесь на обучение сейчас",
  author: metaAuthor,
  titleTemplate: "",
  keywords: [],
  url: "https://itsecurityschool.ru/",
  article: false,
  jsonLdId: "aa638472-e39b-4d16-ba88-be66c6e898df",
  jsonLd: {
    "@context": "https://schema.org/",
    "@type": "Organization",
    url: "https://itsecurityschool.ru",
  },
};

export const metaPageNotFound = {
  title:
    "404 - страница не найдена | Онлайн-школа кибербезопасности и программирования IT-Security School",
  description:
    "404 - страница не найдена | Качественное образование - доступное каждому | Онлайн-школа кибербезопасности и программирования IT-Security School | Запишитесь на обучение онлайн",
  author: metaAuthor,
  url: "https://itsecurityschool.ru/404",
  // canonical: "https://itsecurityschool.ru/404",
  keywords: [],
  robots: "noindex, nofollow",
  content: "404",
  prerenderStatusVode: "404",
  og: {
    locale: "ru_RU",
    type: "website",
    title: "404 - страница не найдена",
    description: "",
    url: "https://itsecurityschool.ru/404",
    image: null,
    imageWidth: "",
    imageHeight: "",
  },
};
