import PropTypes from "prop-types";
import React from "react";
import { Container } from "react-bootstrap";
import SiteSection from "../../../../utils/SiteSection/SiteSection";
import SectionTitle from "../../../../utils/SiteSection/Title/SiteSectionTitle";
import { cnj, getSectionName } from "../../../../utils/Utils";
import { dataSection6 as data } from "../data/course";
import Section6 from "./Section";

const SectionOne = (props) => {
  const { courseName, name, cn } = props;
  const sectionName = getSectionName(courseName, name);

  return (
    <SiteSection name={sectionName} cn={cnj("content-section", cn && `${cn}`)}>
      <Container>
        <SectionTitle
          title={data.title}
          description={data.description}
          cn="section-feature"
          data-aos="fade-up"
        />
      </Container>
      <Section6
        name={sectionName}
        data={data.details[0]}
        cn="content-two-column"
      />
      <Section6
        name={sectionName}
        data={data.details[1]}
        cn="content-two-column"
        odd
      />
    </SiteSection>
  );
};

SectionOne.propTypes = {
  name: PropTypes.string.isRequired,
};

export default SectionOne;
