export const dataСourses = [
  {
    title: "Основы\nинформационной безопасности",
    subtitle: "Основы информационной безопасности (Этичный хакинг)",
    menuName: "Этичный хакинг",
    menuShow: true,
    description:
      "Обучение основам информационной безопасности и этичного хакинга",
    path: "/courses/cyber-security",
  },
  {
    title: "Основы\nадминистрирования ОС",
    subtitle: "Основы администрирования ОС",
    menuName: "Основы администрирования ОС",
    menuShow: false,
    description:
      "Администрирования операционных систем, настройки безопасности",
    path: "/courses/os",
  },
  {
    title: "Подготовка к\nЕГЭ по информатике",
    subtitle: "Подготовка к ЕГЭ (информатика)",
    menuName: "Подготовка к ЕГЭ (информатика)",
    menuShow: false,
    description:
      "Подготовка к ЕГЭ по информатике, изучение основ программирования",
    path: "/courses/ite",
  },
  {
    title: "Программирование на\nPhyton",
    subtitle: "Программирование на Phyton",
    menuName: "Программирование на Phyton",
    menuShow: false,
    description: "Изучение программирования на Python",
    path: "/courses/python",
  },
  {
    title: "Программирование на\nJava",
    subtitle: "Программирование на Java",
    menuName: "Программирование на Java",
    menuShow: false,
    description: "Изучение программирования на Java",
    path: "/courses/java",
  },
  {
    title: "Программирование на\n1C:Предприятие 8.х",
    subtitle: "Программирование на 1C:Предприятие 8.х",
    menuName: "Программирование на 1C:Предприятие",
    menuShow: true,
    description: "Изучение программирования на 1C:Предприятие 8.х",
    path: "/courses/1c-enterprise",
  },
];
