import PropTypes from "prop-types";
import React from "react";
import { Col, Row } from "react-bootstrap";
import PageSection from "../../../../utils/SiteSection/PageSection";
import { cnj } from "../../../../utils/Utils";
import ContactShort from "../../../Contact/ContactShort";
import { dataPrice } from "../data/course";

const Section = (props) => {
  const { courseName, cn, name } = props;

  return (
    <PageSection
      courseName={courseName}
      name={name || "price"}
      cn={cnj("course-price", cn && `${cn}`)}
      data={dataPrice}
    >
      <Row>
        <Col md="4" className="px-sm-2 px-lg-4 order-1 order-md-2">
          <ContactShort eventName={courseName} />
        </Col>
        <Col md="4" className="px-md-4 order-1 order-md-1">
          <div className="content-body-price">
            <div className="content-inner-item">
              <h4>Стоимость курса</h4>
              <h3>{dataPrice.details[0].title}</h3>
              <p></p>
            </div>
            <div className="content-inner-item">
              <h4>Оплата по месяцам без переплаты</h4>
              <h3>{dataPrice.details[0].subtitle}</h3>
              <p>на 12 месяцев</p>
            </div>
          </div>
        </Col>
        <Col md="4" className="px-md-4 order-3">
          <div className="content-body-description">
            <div className="content-inner-item">
              <h4>Возврат денег</h4>
              <p>
                У вас есть две недели занятий, чтобы попробовать. Если
                передумаете учиться, скажите — и мы вернём вам всю сумму.
              </p>
            </div>
          </div>
        </Col>
      </Row>
    </PageSection>
  );
};

Section.propTypes = {
  courseName: PropTypes.string.isRequired,
};

export default Section;
