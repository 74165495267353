import PropTypes from "prop-types";
import React, { Fragment, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  default as ya,
  default as ym,
  YMInitializer,
} from "react-yandex-metrika";
import {
  initGA,
  logEvent as logGAEvent,
  logPageView as logGAPageView,
} from "./GAAnalytics";
import {
  initGTM,
  logEvent as logGTMEvent,
  logPageView as logGTMPageView,
} from "./GTMAnalytics";

const isDev = process.env.NODE_ENV === "development";
const isProd = true; // process.env.NODE_ENV === "production";
const isSnap = true; // navigator.userAgent !== "ReactSnap";

// eslint-disable-next-line no-unused-vars
const logDebug = (...args) => {
  if (isDev) console.debug(args);
};

export const Context = React.createContext({});

export const AnalyticsProvider = ({
  yandexMetrika = null,
  googleAnalytics = null,
  googleTags = null,
  context = Context,
  children,
}) => {
  let locationPath = useLocation();
  const pathname = locationPath ? locationPath.pathname : null;

  const content = (
    <context.Provider
      value={{
        yandexMetrika,
        googleAnalytics,
        googleTags,
        triggerYM: (action, data) => {
          try {
            if (isProd && isSnap) {
              if (yandexMetrika) ya("Conversion", action, data);
            }
          } catch (error) {
            console.error(error);
          }
        },
        triggerGA: (category, action, data) => {
          try {
            if (isProd && isSnap) {
              if (googleAnalytics) {
                logGAEvent(category, action, data);
              }
              //if (googleTags) logGTMEvent("event", action, data);
            }
          } catch (error) {
            console.error(error);
          }
        },
        triggerGTM: (category, action, data) => {
          try {
            if (isProd && isSnap) {
              if (googleTags) logGTMEvent(category, action, data);
            }
          } catch (error) {
            console.error(error);
          }
        },
        hit: (data) => {
          try {
            if (isProd && isSnap) {
              if (yandexMetrika) ym("hit", data);
            }
          } catch (error) {
            console.error(error);
          }
        },
      }}
    >
      {children}
    </context.Provider>
  );

  useEffect(() => {
    if (!process.browser || !pathname) return content;
    if (isProd && isSnap) {
      // Use GA4 if not GTM
      if (googleAnalytics && !googleTags) initGA();
      // Use GTM
      if (googleTags) initGTM();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!process.browser || !pathname) return content;

    if (isProd && isSnap) {
      if (yandexMetrika) ym("hit", pathname);
      // Use GA4 if not GTM
      if (googleAnalytics && !googleTags) logGAPageView(pathname);
      // Use GTM
      if (googleTags) logGTMPageView(pathname);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  if (!process.browser || !pathname) return content;

  return (
    <Fragment>
      {isProd && isSnap && !!yandexMetrika && (
        <YMInitializer
          accounts={[yandexMetrika]}
          options={{
            clickmap: true,
            trackLinks: true,
            accurateTrackBounce: true,
            webvisor: false,
            trackHash: true,
          }}
          version="2"
        />
      )}
      {content}
    </Fragment>
  );
};

AnalyticsProvider.propTypes = {
  yandexMetrika: PropTypes.number.isRequired,
};

export default AnalyticsProvider;
