import React, { useEffect, useState } from "react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { dataСourses } from "../../data/courses";

const TopMenu = () => {
  const [isSticky, setSticky] = useState(false);

  const handleScroll = () => {
    const show = window.scrollY >= 200;

    if (show) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  };

  useEffect(() => {
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header
      id="top-menu"
      className={
        isSticky ? "top-menu fixed-top scrolled" : "top-menu fixed-top"
      }
    >
      <Navbar variant="dark" className="top-menu-navbar" expand="md">
        <Container>
          <Navbar.Brand href="/">
            <img
              alt=""
              src="/images/logo064.png"
              width="30"
              height="30"
              className="d-inline-block align-top top-menu-navbar-icon"
            />{" "}
            Онлайн-школа
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="top-menu-navbar-nav" />
          <Navbar.Collapse id="top-menu-navbar-nav">
            <Nav className="top-menu-navbar-nav">
              <Nav.Link href="/" bsPrefix="top-menu-nav-link nav-link">
                Home
              </Nav.Link>
              <NavDropdown title="Курсы" id="top-menu-navbar-dropdown">
                {dataСourses
                  .filter((item) => item.menuShow === true)
                  .map((item, index) => {
                    return (
                      <NavDropdown.Item
                        key={index}
                        href={item.path}
                        bsPrefix="top-menu-nav-link nav-link"
                      >
                        {item.menuName || item.title}
                      </NavDropdown.Item>
                    );
                  })}
              </NavDropdown>
              <Nav.Link href="/blog" bsPrefix="top-menu-nav-link nav-link">
                Блог
              </Nav.Link>
              <Nav.Link href="/contact" bsPrefix="top-menu-nav-link nav-link">
                Контакты
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default TopMenu;

/*

<Navbar.Toggle aria-controls="navbar-scroll" />

              <NavDropdown title="Курсы" id="basic-nav-dropdown">
                <NavDropdown.Item href="#action/3.1">
                  Основы информационной безопасности
                </NavDropdown.Item>
                <NavDropdown.Item href="#action/3.2">
                  Основы администрирования ОС
                </NavDropdown.Item>
                <NavDropdown.Item href="#action/3.3">
                  Подготовка к ЕГЭ по информатике
                </NavDropdown.Item>
                <NavDropdown.Item>Программирование на Phyton</NavDropdown.Item>
              </NavDropdown>
              */
