import PropTypes from "prop-types";
import React from "react";
import { SiteSectionFAQ } from "../../../TypicalSections";
import { dataFAQ as data } from "../data/course";

const FAQ = (props) => {
  const { courseName } = props;

  return (
    <SiteSectionFAQ
      courseName={courseName}
      data={data}
      addCommonFAQ
      chunkSize={5}
    />
  );
};

FAQ.propTypes = {
  courseName: PropTypes.string.isRequired,
};

export default FAQ;
