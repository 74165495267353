import React from "react";
import { Col, FloatingLabel, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const ContactShortForm = (props) => {
  const buttonLabel = "Записаться";

  const InputPolicyLabel = () => {
    return (
      <span className="form-check-label-text">
        {`Нажимая на кнопку, Вы выражаете свое согласие с нашими
        условиями обработки`}{" "}
        <strong>
          <Link to="/privacy">персональных данных</Link>.
        </strong>
      </span>
    );
  };

  return (
    <Form
      className="contact-short-form"
      onSubmit={props.onSubmit}
      id="contact-short-form"
    >
      <Row className="mb-3">
        <Col>
          <FloatingLabel controlId="inputFullname" label="Имя">
            <Form.Control
              type="text"
              className="input"
              placeholder="Имя"
              name="fullname"
              required
              pattern="([a-zA-Z]+\s){0,}([a-zA-Z]+)"
              data-error="Ошибка ввода имени"
            />
          </FloatingLabel>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <FloatingLabel controlId="formPhone" label="Номер телефона">
            <Form.Control
              type="text"
              className="form-control input"
              placeholder="+7(999)999-99-99"
              name="phone"
              data-error="Ошибка ввода номера телефона"
            />
          </FloatingLabel>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <FloatingLabel controlId="formEmail" label="Электронная почта">
            <Form.Control
              type="email"
              className="input"
              placeholder="Электронная почта"
              name="email"
              required
              size="64"
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
              data-error="Ошибка ввода адреса почты"
            />
          </FloatingLabel>
        </Col>
      </Row>
      <div className="text-center">
        <button className="btn btn-new" type="submit" onClick={() => {}}>
          {buttonLabel}
        </button>
        <InputPolicyLabel />
      </div>
    </Form>
  );
};

export default ContactShortForm;

/*
        <ButtonToNavigate
          className="btn btn-new"
          type="submit"
          title={buttonLabel}
        ></ButtonToNavigate>
*/
