import React from "react";

const Paragraph = ({ text, span, ...restProps }) => {
  //const { text, span, className } = props;
  //console.log(text);

  const newText = text.split("\n").map((str, index) =>
    span ? (
      <span key={index}>{str}</span>
    ) : (
      <p key={index} {...restProps}>
        {str}
      </p>
    )
  );
  return newText;
};

export default Paragraph;
