import emailjs from "emailjs-com";
import AppConfig from "../../../App.config";

/**
 * ENVIRONMENT VARIABLES
 **/
const serviceId = AppConfig.EMAIL_SERVICE.SERVICE_ID || "";
const templateId = AppConfig.EMAIL_SERVICE.SERVICE_TEMPLATE1 || "";
const userID = AppConfig.EMAIL_SERVICE.USER_ID || "";

export const SendFeedback = (templateParams, setSubmit, setError) => {
  try {
    emailjs
      .send(serviceId, templateId, templateParams, userID)
      .then((response) => {
        console.log(
          "Email had been submitted!",
          response.status,
          response.text
        );
        setSubmit(true);
        setError(false);
      })
      .catch((err) => {
        console.error("Oh well, you failed. Error that occured:", err);
        setSubmit(false);
        setError(true);
      });
  } catch (err) {
    console.error("Oh well, you failed. Error that occured:", err);
    setError(true);
  }
};

export const SendFeedbackS = async (
  serviceId,
  templateId,
  variables,
  setSubmit,
  setError
) => {
  try {
    window.emailjs
      .send(serviceId, templateId, variables)
      .then((res) => {
        setSubmit(true);
        setError(false);
      })
      // Handle errors here however you like, or use a React error boundary
      .catch((err) => {
        console.error(
          "Oh well, you failed. Here some thoughts on the error that occured:",
          err
        );
        setSubmit(false);
        setError(true);
      });
  } catch (err) {
    console.err(err);
    setError(true);
  }
};
