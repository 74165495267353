import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Accordion, Col, Row } from "react-bootstrap";
import Paragraph from "../../../utils/Paragraph/Paragraph";
import PageSection from "../../../utils/SiteSection/PageSection";
import { cnj } from "../../../utils/Utils";

const dataCommonFAQ = [
  {
    title: "Есть ли варианты оплаты в рассрочку?",
    description:
      "Да, наши банки-партнеры предоставляют рассрочку (без переплаты) на 6 или 12 месяцев. Подробные условия вы сможете узнать у нашего менеджера.",
  },
  {
    title: "Можно ли сделать отмену и возврат?",
    description:
      "Да, мы можем вернуть вам средства полностью или частично, в зависимости от срока обучения. Мы всегда внимательны к нашим клиентам и в каждом индивидуальном.",
  },
];

const FAQ = (props) => {
  const { courseName, cn, name, data, chunkSize, addCommonFAQ } = props;

  const [groups, setGroups] = useState([]);
  const [listQuestions, setListQuestions] = useState([]);

  useEffect(() => {
    if (addCommonFAQ) {
      const listProm = [...data.details, ...dataCommonFAQ];
      setListQuestions(listProm);
    }
    // console.log(listQuestions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const groupsList = listQuestions
      .map((e, i) => {
        return i % chunkSize === 0
          ? listQuestions.slice(i, i + chunkSize)
          : null;
      })
      .filter((e) => {
        return e;
      });
    setGroups(groupsList);
  }, [chunkSize, listQuestions]);

  return (
    <PageSection
      courseName={courseName}
      name={name || "faq"}
      cn={cnj("course-faq", cn && `${cn}`)}
      cnt="section-feature"
      data={data}
    >
      <Row>
        {groups &&
          groups.map((itemGroup, indexGroup) => {
            return (
              <Col lg="6" className="content" key={indexGroup}>
                <Accordion className="accordion accordion-flush" id="faqlist1">
                  {itemGroup.map((item, index) => {
                    return (
                      <Accordion.Item
                        eventKey={indexGroup === 0 ? index : index * indexGroup}
                        key={index * (indexGroup + 1)}
                      >
                        <Accordion.Header>{item.title}</Accordion.Header>
                        <Accordion.Body>
                          <Paragraph text={item.description} />
                        </Accordion.Body>
                      </Accordion.Item>
                    );
                  })}
                </Accordion>
              </Col>
            );
          })}
      </Row>
    </PageSection>
  );
};

FAQ.propTypes = {
  chunkSize: PropTypes.number,
};

FAQ.defaultProps = {
  chunkSize: 5,
};

export default FAQ;
