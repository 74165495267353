import React from "react";
import { cnj } from "../Utils";

const SiteSection = (props) => {
  const { name, cn, ...restProps } = props;

  return (
    <section
      className={cnj("site-section", `section-${name}`, cn && `${cn}`)}
      id={`section-${name}`}
      name={`section-${name}`}
      {...restProps}
    >
      {props.children}
    </section>
  );
};

export default SiteSection;
