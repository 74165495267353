import React, { Fragment } from "react";
import { Col, Row } from "react-bootstrap";
import Paragraph from "../../../utils/Paragraph/Paragraph";
import { cnj, isOdd } from "../../../utils/Utils";

const ChessContent = (props) => {
  const { name, data, cn } = props;

  return (
    <Fragment>
      {data.details.map((item, index) => {
        const orderLeftClassName = isOdd(index) ? "order-lg-2" : "order-lg-1";
        const orderRightClassName = isOdd(index) ? "order-lg-1" : "order-lg-2";
        const oddClassName = isOdd(index) ? "odd" : "";
        const lastClassName = index < data.details.length - 1 ? "mb-5" : "";

        // console.log(data.details.length);

        return (
          <Row
            className={cnj(
              "content-chess align-items-center justify-content-around",
              lastClassName,
              cn && `${cn}`
            )}
            key={index}
          >
            <Col
              lg="6"
              xl="5"
              className={cnj("order-1", orderLeftClassName, oddClassName)}
            >
              <figure className="image-box" data-aos={item.dataAOS}>
                <img
                  className="img-fluid"
                  src={item.image.src}
                  alt={
                    data.image?.alt
                      ? data.image.alt
                      : "Инсценировка проникновения в ИТ-инфраструктуру (Pentest)"
                  }
                  title={data.image?.title}
                />
              </figure>
            </Col>
            <Col
              lg="6"
              className={cnj("order-2", orderRightClassName, "intro-text")}
            >
              <div className={cnj("content-body", `content-body-${name}`)}>
                <h3 className="content-title">{item.title}</h3>
                <Paragraph
                  className="content-text"
                  text={item.description}
                ></Paragraph>
              </div>
            </Col>
          </Row>
        );
      })}
    </Fragment>
  );
};

export default ChessContent;
