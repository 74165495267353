import React, { useState } from "react";
import { Carousel } from "react-bootstrap";
import { Link } from "react-router-dom";

const СourseCarousel = (props) => {
  const { data } = props;
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  //<p>{item.description}</p>   <Carousel.Caption> </Carousel.Caption>
  return (
    <Carousel activeIndex={index} onSelect={handleSelect} className="d-block">
      {data &&
        data.map((item, index) => {
          return (
            <Carousel.Item key={index}>
              <div className="banner-carousel-caption d-block">
                <Link to={item.link}>
                  <h3>{item.title}</h3>
                </Link>
              </div>
            </Carousel.Item>
          );
        })}
    </Carousel>
  );
};

export default СourseCarousel;
