import React from "react";
import { Container } from "react-bootstrap";
import { cnj, getSectionName } from "../Utils";
import SiteSection from "./SiteSection";
import SectionTitle from "./Title/SiteSectionTitle";

const PageSection = (props) => {
  const { name, courseName, cn, cnt, data, hideTitle, style } = props;
  const sectionName = getSectionName(courseName, name);
  const isSectionTitle = data.title || data.description;

  return (
    <SiteSection
      name={sectionName}
      cn={cnj("content-section", cn && `${cn}`)}
      style={style}
    >
      <Container>
        {hideTitle
          ? null
          : isSectionTitle && (
              <SectionTitle
                title={data.title}
                description={data.description}
                cn={cnj("", cnt && `${cnt}`)}
                data-aos="fade-up"
              />
            )}
        {props.children}
      </Container>
    </SiteSection>
  );
};

export default PageSection;
