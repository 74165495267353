import React, { Fragment, lazy, Suspense, useEffect } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import PageLoader from "./components/layout/PageLoader";
import HomePage from "./components/pages/HomePage";
import { RouterNotFoundPage } from "./components/utils/Utils";

const BlogPage = lazy(() => import("./components/pages/BlogPage"));
const ContactPage = lazy(() => import("./components/pages/ContactPage"));
const NotFoundPage = lazy(() => import("./components/pages/NotFoundPage"));
const OfferContractPage = lazy(() =>
  import("./components/pages/OfferContractPage")
);
const PrivacyPolicyPage = lazy(() =>
  import("./components/pages/PrivacyPolicyPage")
);
const SecurityClubPage = lazy(() =>
  import("./components/pages/SecurityClubPage")
);
const ThanksPage = lazy(() => import("./components/pages/ThanksPage"));
const CoursesPage = lazy(() => import("./components/pages/СoursesPage"));
const CourseITSPage = lazy(() =>
  import("./components/sections/Cources/Course1/CoursePage")
);
const Course1CPage = lazy(() =>
  import("./components/sections/Cources/Course5/CoursePage")
);

const Router = () => {
  //let { path, url } = useRouteMatch();
  //let match = useRouteMatch();
  let location = useLocation();

  const pathname = location ? location.pathname : null;
  const query = location ? location.search : null;

  useEffect(() => {}, []);

  useEffect(() => {
    if (!process.browser || !pathname) return;

    // trigger("hit", pathname);
    // hit(pathname);
  }, [pathname]);

  console.debug("Path", pathname, query);
  // {`/home/${query}`}
  // <Route exect path="/home" component={HomePage} />
  // <Redirect exact from="/" to={`/home${query}`} query={query} />

  return (
    <Fragment>
      <Suspense fallback={<PageLoader />}>
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route exect path="/blog" component={BlogPage} />
          <Route exect path="/courses/1c-enterprise" component={Course1CPage} />
          <Route
            exect
            path="/courses/cyber-security"
            component={CourseITSPage}
          />
          <Route exect path="/courses" component={CoursesPage} />
          <Route exect path="/securityclub" component={SecurityClubPage} />
          <Route exect path="/event/registration" component={ThanksPage} />
          <Route exect path="/contact" component={ContactPage} />
          <Route exect path="/privacy" component={PrivacyPolicyPage} />
          <Route exect path="/offercontract" component={OfferContractPage} />
          <Route exect path="/404" component={NotFoundPage} />
          <Redirect exact from="/home" to="/" query={query} />
          <RouterNotFoundPage />
        </Switch>
      </Suspense>
    </Fragment>
  );
};

export default Router;
