/* eslint-disable import/no-anonymous-default-export */
export default {
  GOOGLE: {
    GAPI: "",
    GA_TRACKING_CODE: process.env.REACT_APP_GA_TRACKING_CODE,
    GTM_TRACKING_CODE: process.env.REACT_APP_GTM_TRACKING_CODE,
    GA_TRACKING_ON_DEV: process.env.REACT_APP_GA_TRACKING_ON_DEV,
  },
  YANDEX: {
    YM_TRACKING_CODE: process.env.REACT_APP_YM_TRACKING_CODE,
    YM_TRACKING_ON_DEV: process.env.REACT_APP_YM_TRACKING_ON_DEV,
  },
  EMAIL_SERVICE: {
    SERVICE_ID: process.env.REACT_APP_EMAIL_SERVICE_ID,
    USER_ID: process.env.REACT_APP_EMAIL_SERVICE_USER_ID,
    SERVICE_TEMPLATE1: process.env.REACT_APP_EMAIL_SERVICE1_TEMPLATE,
  },
};
