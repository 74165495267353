import PropTypes from "prop-types";
import React from "react";
import PageSection from "../../../../utils/SiteSection/PageSection";
import ChessContent from "../../../TypicalSections/ChessContent/ChessContent";
import { dataSection2 as data } from "../data";

const Section = (props) => {
  const { courseName, name } = props;

  return (
    <PageSection
      courseName={courseName}
      name={name}
      data={data}
      cnt="section-feature"
    >
      <ChessContent name={`${name}`} data={data} cn="" />
    </PageSection>
  );
};

Section.propTypes = {
  courseName: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default Section;
