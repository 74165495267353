import React, { useState } from "react";
import { Carousel } from "react-bootstrap";

/**
 *
 * https://codepen.io/jakaprima/pen/gGmjZG
 */

const ControlledCarousel = (props) => {
  const { ...restProps } = props;
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const handleSlide = (e) => {};

  const handleSlid = (e) => {};

  return (
    <Carousel
      activeIndex={index}
      onSelect={handleSelect}
      onSlide={handleSlide}
      onSlid={handleSlid}
      variant="dark"
      controls={true}
      indicators={false}
      {...restProps}
    >
      {props.children}
    </Carousel>
  );
};

export default ControlledCarousel;
