import React from "react";
import { Col, Row } from "react-bootstrap";
import { cnj } from "../../../utils/Utils";

const ServiceContent = (props) => {
  // eslint-disable-next-line no-unused-vars
  const { name, data, order, cn } = props;

  const blockCN1 =
    order === "right" ? "order-2 order-lg-1" : "order-2 order-lg-2";
  const blockCN2 =
    order === "right" ? "order-1 order-lg-2" : "order-2 order-lg-1";

  const childrenWithProps = (item, indexItem, cn) => {
    return React.Children.map(props.children, (child, i) => {
      return React.cloneElement(child, {
        index: indexItem,
        data: item,
        cn: cn,
      });
    });
  };

  return (
    <Row className={cnj("", cn && `${cn}`)}>
      <Col lg="6" className={cnj(blockCN1)}>
        {data.details.map((item, index) => {
          return childrenWithProps(item, index, cn);
        })}
      </Col>
      <Col
        lg="6"
        className={cnj(blockCN2)}
        data-aos="fade-left"
        data-aos-delay="100"
      >
        <figure className="image-box">
          <img
            className="d-block w-100 image"
            src={data.image.src}
            alt={data.image.alt}
            title={data.image.title}
          />
        </figure>
      </Col>
    </Row>
  );
};

export default ServiceContent;

// {cnj("column-content", `column-content-${name}`)}
