import ReactGA from "react-ga";
import ReactGA4 from "react-ga4";
import AppConfig from "../App.config";

const isDev = process.env.NODE_ENV === "development";
//const isDev = process.env.NODE_ENV || process.env.NODE_ENV === "development";
const GA_TRACKING_ID = AppConfig.GOOGLE.GA_TRACKING_CODE || "";
const isGA4 = GA_TRACKING_ID.indexOf("UA-") === -1;

const logDebug = (...args) => {
  if (isDev) console.debug(args);
};

const initGA = () => {
  // Enable debug mode on the local development environment
  logDebug("GA4 init", GA_TRACKING_ID, isDev);

  if (isGA4) {
    // TODO: https://developers.google.com/analytics/devguides/collection/analyticsjs/field-reference?hl=ru
    //gaOptions: { alwaysSendReferrer: true },
    ReactGA4.initialize([
      {
        trackingId: GA_TRACKING_ID,
        testMode: isDev,
        debug: isDev,
        gaOptions: {},
      },
    ]);
  } else {
    ReactGA.initialize(GA_TRACKING_ID, {
      debug: isDev,
    });
  }
};

const logPageView = (url) => {
  logDebug(`GA4 logging pageview for ${url}`, GA_TRACKING_ID, isDev);

  if (isGA4) {
    // Send automatic
    // ReactGA4.ga("send", { _event: "gtm.js", hitType: "pageview" });
    //ReactGA4.send("pageview");
    //ReactGA4.send("pageview", { page: url });
    //ReactGA4.send({ hitType: "pageview", page: url });
    //ReactGA4.gtag("send", "page_view", {
    //  pagePath: url,
    //});
    // ReactGA4.event send({ page: url });
    // ReactGA4.send("pageview", { page: url });
  } else {
    ReactGA.set({ page: url });
    ReactGA.pageview(url);
  }
};

const logEvent = (category = "", action = "", label = "") => {
  logDebug(`GA4 logging event`, category, action, label);

  if (category && action) {
    if (isGA4) {
      ReactGA4.event({ category: category, action: action, label: label });
    } else {
      ReactGA.event({ category, action, label });
    }
  }
};

const logException = (description = "", fatal = false) => {
  logDebug(`GA4 logging exeption`, description, fatal);

  if (description) {
    if (isGA4) {
      ReactGA4.exception({ description, fatal });
    } else {
      ReactGA.exception({ description, fatal });
    }
  }
};

export { initGA, logPageView, logEvent, logException };
