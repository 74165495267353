import { useEffect, useState } from "react";

const getIsMobile = () => window.innerWidth <= 1024;
// const getWidth = () =>
//  window.innerWidth ||
//  document.documentElement.clientWidth ||
//  document.body.clientWidth;

export default function useIsMobile() {
  const [isMobile, setIsMobile] = useState(getIsMobile());

  useEffect(() => {
    const onResize = () => {
      setIsMobile(getIsMobile());
    };

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  return isMobile;
}
