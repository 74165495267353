import { RiCheckDoubleLine } from "@react-icons/all-files/ri/RiCheckDoubleLine";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { ImgWithFallback } from "../../../../utils/LazyImage/ImgWithFallback";
import Paragraph from "../../../../utils/Paragraph/Paragraph";
import { cnj } from "../../../../utils/Utils";

const ListItems = (props) => {
  const { data, className } = props;
  const ListItems = data.map((item, index) => (
    <li className="content-list-group-item" key={index}>
      <RiCheckDoubleLine />
      {item}
    </li>
  ));
  return (
    <ul className={cnj("content-list-group", className && `${className}-list`)}>
      {data && ListItems}
    </ul>
  );
};

const Section = (props) => {
  const { name, data, cn, odd } = props;
  const orderLeftClassName = odd ? "order-lg-2" : "order-lg-1";
  const orderRightClassName = odd ? "order-lg-1" : "order-lg-2";
  const oddClassName = odd ? "ml-xl-auto" : "mr-xl-auto";

  return (
    <div className={cnj("content-wrapper", odd ? "odd" : "")}>
      <Container>
        <Row className={cnj("mb-5 align-items-center", cn && `${cn}`)}>
          <Col
            lg="6"
            xl="5"
            className={cnj("order-1", orderLeftClassName, oddClassName)}
          >
            <figure className="image-box">
              <ImgWithFallback
                className="d-block w-100"
                src={data.image}
                fallback={data.imagePNG}
                alt={
                  data.alt
                    ? data.alt
                    : "Инсценировка проникновения в ИТ-инфраструктуру (Pentest)"
                }
              />
            </figure>
          </Col>
          <Col
            lg="6"
            className={cnj("order-2 intro-text my-lg-5", orderRightClassName)}
          >
            <div className={cnj("content-body", `content-body-${name}`)}>
              {data.title && (
                <h3 className={cnj("content-title", `content-title-${name}`)}>
                  {data.title}
                </h3>
              )}
              <Paragraph
                className={cnj("content-text", `content-text-${name}`)}
                text={data.description}
              ></Paragraph>
              {data.detailsTitle && (
                <h5
                  className={cnj(
                    "content-subtitle",
                    `content-subtitle-${name}`
                  )}
                >
                  {data.detailsTitle}
                </h5>
              )}
              {data.details && <ListItems data={data.details} />}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Section;
