import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Image1 from "../../../../../images/Icons/calendar.png";
import Image2 from "../../../../../images/Icons/money.png";
import { cnj } from "../../../../utils/Utils";

const data = {
  title: "",
  subtitle: "",
  description: "",
  details: [
    {
      title: "4 месяца обучения",
      description:
        ": более 100 практических задач и отработка заданий в виртуальной лаборатории",
      icon: Image1,
      alt: "",
    },
    {
      title: "От 150 000 ₽",
      description:
        " — старт уровня дохода специалиста со знаниями в ИБ и предшествующем опытом в IT",
      icon: Image2,
      alt: "",
    },
  ],
  buttons: [],
};

const SectionSubHero = (props) => {
  // eslint-disable-next-line no-unused-vars
  const { name } = props;

  return (
    <section
      className={cnj("section-subhero", `section-subhero-${name}`)}
      name={`sectionsub-hero-${name}`}
      id="subhero"
    >
      <Container>
        <Row className="row text-center">
          {data.details.map((item, index) => {
            return (
              <Col lg="6" key={index}>
                <div className="banner-text">
                  <img src={item.icon} alt={item.alt} />
                  <p>
                    <span>{item.title}</span>
                    {item.description}
                  </p>
                </div>
              </Col>
            );
          })}
        </Row>
      </Container>
    </section>
  );
};

export default SectionSubHero;
