import React from "react";
import { Col, Row } from "react-bootstrap";
import { cnj } from "../../../utils/Utils";

const TwoColumnContent = (props) => {
  // eslint-disable-next-line no-unused-vars
  const { cn, data, chunkSize } = props;

  const groups = data
    .map((e, i) => {
      return i % chunkSize === 0 ? data.slice(i, i + chunkSize) : null;
    })
    .filter((e) => {
      return e;
    });

  const childrenWithProps = (item, indexItem, indexGroup, cn) => {
    return React.Children.map(props.children, (child, i) => {
      return React.cloneElement(child, {
        index: indexItem,
        data: item,
        cn: cn,
      });
    });
  };

  return (
    <Row className={cnj("", cn && `${cn}`)}>
      {groups.map((itemGroup, indexGroup) => {
        return (
          <Col lg="6" className="" key={indexGroup}>
            {itemGroup.map((item, indexItem) => {
              return childrenWithProps(item, indexItem, indexGroup, cn);
            })}
          </Col>
        );
      })}
    </Row>
  );
};

export default TwoColumnContent;
