import React from "react";
import { Helmet } from "react-helmet-async";
import { metaSite } from "../data/SEO/pagemeta";

// const isProd = process.env.NODE_ENV === "production";
// const isDev = process.env.NODE_ENV === "development";

export const AppMeta = () => {
  // console.log("isDev: ", isDev, process.env.NODE_ENV.trim() === "development");
  // console.log("isDev: ", isDev, process.env.NODE_ENV === "development");

  const ORG_SCHEMA = JSON.stringify(metaSite.jsonLd);

  return (
    <Helmet title={metaSite.title} titleTemplate={metaSite.titleTemplate}>
      <html lang="ru" />
      {/* <meta http-equiv="Content-Type" content="text/html; charset=utf-8" /> */}
      <meta name="description" content={metaSite.description} />
      <meta name="keywords" content={metaSite.keywords.join(",")} />
      <meta name="author" content={metaSite.author} />
      {/* GA Verification */}
      <meta
        name="google-site-verification"
        content="VwXHVhhq4DkSZKCrP4VaP2wPGr3n6ZINu9DLgrRtXQY"
      />
      {/* FbB Verification */}
      <meta
        name="facebook-domain-verification"
        content="gr0rt673op8n14vz7xhhv9bd8fuf6c"
      />
      {/* base element */}
      {/*!isDev && <base target="_blank" href="https://itsecurityschool.ru/" />*/}
      {/*!isDev && dataWebSite.canonical && (
        <link rel="canonical" href={dataWebSite.canonical} />
      )*/}
      {metaSite.title && <meta property="og:title" content={metaSite.title} />}
      {metaSite.description && (
        <meta property="og:description" content={metaSite.description} />
      )}
      {metaSite.url && <meta property="og:url" content={metaSite.url} />}
      {(metaSite.article ? true : null) && (
        <meta property="og:type" content="article" />
      )}
      {/* ld+json */}
      {metaSite.jsonLd && (
        <script type="application/ld+json" key={metaSite.jsonLdId}>
          {ORG_SCHEMA}
        </script>
      )}
      <meta name="ROBOTS" content="INDEX" />
    </Helmet>
  );
};
