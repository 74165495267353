import React, { useEffect, useState } from "react";
import VisibilitySensor from "react-visibility-sensor";

const Counter = (props) => {
  const { label, number, duration } = props.data;
  const [count, setCount] = useState("0");
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (!isVisible) return;

    const end = parseInt(number.substring(0, 3)); // first three numbers from props
    let start = 0;

    if (start === end) return;

    // find duration per increment
    let totalMilSecDur = parseInt(duration);
    let incrementTime = (totalMilSecDur / end) * 1000;

    let timer = setInterval(() => {
      start += 1;
      setCount(String(start) + number.substring(3));
      if (start === end) clearInterval(timer);
    }, incrementTime);
  }, [number, duration, isVisible]);

  useEffect(() => {
    // console.log(isVisible);
  }, [isVisible]);

  return (
    <VisibilitySensor
      onChange={(visible) => {
        setIsVisible(visible);
      }}
    >
      <div className="count-item text-center mb-5 mb-lg-0">
        <span>{count}</span>
        <p>{label}</p>
      </div>
    </VisibilitySensor>
  );
};

export default Counter;
