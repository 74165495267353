import { FaFacebookF } from "@react-icons/all-files/fa/FaFacebookF";
import { FaInstagram } from "@react-icons/all-files/fa/FaInstagram";
import { FaTelegram } from "@react-icons/all-files/fa/FaTelegram";
import { FaYoutube } from "@react-icons/all-files/fa/FaYoutube";
import React from "react";
import { dataSocialLinks } from "../../../../data/site";

const SocialLinks = () => {
  return (
    <ul className="social-links list-unstyled mb-4">
      <li className="my-2 me-3">
        <a
          rel="noopener noreferrer"
          href={dataSocialLinks.youtube}
          target="_blank"
          className="youtube"
        >
          <FaYoutube />
          <span className="visually-hidden">YouTube</span>
        </a>
      </li>
      <li className="my-2 me-3">
        <a
          rel="noopener noreferrer"
          href={dataSocialLinks.telegram}
          target="_blank"
          className="telegram"
        >
          <FaTelegram />
          <span className="visually-hidden">Telegram</span>
        </a>
      </li>
      <li className="my-2 me-3">
        <a
          rel="noopener noreferrer"
          href={dataSocialLinks.facebook}
          target="_blank"
          className="facebook"
        >
          <FaFacebookF />
          <span className="visually-hidden">Facebook</span>
        </a>
      </li>
      <li className="my-2 me-3">
        <a
          rel="noopener noreferrer"
          href={dataSocialLinks.instagram}
          target="_blank"
          className="instagram"
        >
          <FaInstagram />
          <span className="visually-hidden">Instagram</span>
        </a>
      </li>
    </ul>
  );
};

export default SocialLinks;
