import React from "react";
import { metaPageHome } from "../../data/SEO/pagemeta";
import { PageMeta } from "../../SEO/PageMeta";
import FAQ from "../sections/Cources/Course1/FAQ/FAQ";
import { Section1 } from "../sections/Cources/Course1/Section1";
import { Section2 } from "../sections/Cources/Course1/Section2";
import { Section3 } from "../sections/Cources/Course1/Section3";
import { Section4 } from "../sections/Cources/Course1/Section4";
import { Section5 } from "../sections/Cources/Course1/Section5";
import { SectionOne as Section6One } from "../sections/Cources/Course1/Section6";
import { SectionCounts } from "../sections/Cources/Course1/SectionCountUp";
import {
  SectionHero,
  SectionSubHero,
} from "../sections/Cources/Course1/SectionHero";
import { SectionPrice } from "../sections/Cources/Course1/SectionPrice";
import { SectionProgram } from "../sections/Cources/Course1/SectionProgram";
import { cnj } from "../utils/Utils";

const HomePage = () => {
  const courseName = "course1"; // Course - IT Security

  return (
    <main id="main" className="main-page-home">
      <article
        className={cnj("course-single-page", `course-page-${courseName}`)}
      >
        <PageMeta metaPage={metaPageHome} />
        <SectionHero name={courseName} />
        <SectionSubHero name={courseName} />
        <Section1 courseName={courseName} name="section1" />
        <Section3 courseName={courseName} name="section3" />
        <Section2 courseName={courseName} name="section2" />
        <SectionCounts courseName={courseName} />
        <Section6One courseName={courseName} name="section6" />

        <SectionProgram courseName={courseName} />
        <Section5 courseName={courseName} name="section5" />
        <Section4 courseName={courseName} name="section4" />
        <FAQ courseName={courseName} />
        <SectionPrice courseName={courseName} />
      </article>
    </main>
  );
};

export default HomePage;
