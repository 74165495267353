//const DEBUG = process.env.REACT_APP_DEBUG;

import { useEffect } from "react";
import { Route, useHistory } from "react-router-dom";

export const cnj = (...args) => args.filter(Boolean).join(" ");

export const isOdd = (x) => {
  return x & 1;
};

export const isEven = (x) => {
  return !(x & 1);
};

export const getSectionName = (courseName, name) => {
  return `${courseName}-${name}`;
};

export const groupBy = (items, key) =>
  items.reduce(
    (result, item) => ({
      ...result,
      [item[key]]: [...(result[item[key]] || []).concat(key), item],
    }),
    {}
  );

export const formatDataShort = (date) => {
  var options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  let dateFormat = new Date();
  dateFormat.setTime(Date.parse(date));
  return dateFormat.toLocaleString("ru", options);
};

export const chunkArray = (chunk_array, chunk_size) => {
  var index = 0;
  const arrayLength = chunk_array.length;
  var tempArray = [];

  for (index = 0; index < arrayLength; index += chunk_size) {
    const myChunk = chunk_array.slice(index, index + chunk_size);
    tempArray.push(myChunk);
  }

  return tempArray;
};

export const Status = ({ code, children }) => (
  <Route
    render={({ staticContext }) => {
      if (staticContext) staticContext.status = code;
      return children;
    }}
  />
);

export const RouterNotFoundPage = () => {
  const history = useHistory();

  useEffect(() => {
    history.push("/404");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export const ExternalLink = ({ to, label, ...restProps }) => {
  return (
    <a href={to} target="_blank" rel="noreferrer" {...restProps}>
      {label}
    </a>
  );
};
