import React from "react";
import { cnj } from "../../Utils";

const SectionTitle = (props) => {
  const {
    title,
    subtitle,
    description,
    cn,
    extendedTitle,
    isBackground,
    ...restProps
  } = props;

  return (
    <div
      className={cnj(isBackground ? "section-title-bg" : "section-title", cn)}
      {...restProps}
    >
      <h2 className="fs-1">{title}</h2>
      {extendedTitle && subtitle && <h3>{subtitle}</h3>}
      {extendedTitle && description && <p>{description}</p>}
    </div>
  );
};

export default SectionTitle;
