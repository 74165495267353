import PropTypes from "prop-types";
import React from "react";
import PageSection from "../../../../utils/SiteSection/PageSection";
import { cnj } from "../../../../utils/Utils";
import TwoColumnContent from "../../../TypicalSections/TwoColumnContent/TwoColumnContent";
import { dataSection4 as data } from "../data/course";

const InnerItem = (props) => {
  const { name, data, cn } = props;

  return (
    <div
      className={cnj(
        "content-inner-item",
        `content-inner-item-${name}`,
        cn && `${cn}-inner-item`
      )}
    >
      {data.image && (
        <div
          className={cnj("content-title-icon", `content-title-icon-${name}`)}
        >
          <span className="filter"></span>
          {data.image}
        </div>
      )}
      <div className={cnj("content-body", `content-body-${name}`)}>
        {data.title && (
          <h4 className={cnj("content-title", `content-title-${name}`)}>
            {data.title}
          </h4>
        )}
        {data.description && (
          <p className={cnj("content-text", `content-text-${name}`)}>
            {data.description}
          </p>
        )}
      </div>
    </div>
  );
};

const Section = (props) => {
  const { courseName, name } = props;

  return (
    <PageSection
      courseName={courseName}
      name={name}
      data={data}
      cn="section-bg1"
      cnt="section-feature"
    >
      <TwoColumnContent
        name={`${name}`}
        data={data.details}
        cn="content-grid"
        chunkSize="3"
      >
        <InnerItem name={`${name}`} />
      </TwoColumnContent>
    </PageSection>
  );
};

Section.propTypes = {
  courseName: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default Section;
