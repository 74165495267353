import { RiCheckDoubleLine } from "@react-icons/all-files/ri/RiCheckDoubleLine";
import React from "react";
import Section1Image2 from "../../../../../images/blog1.jpg";
import {
  default as Section6Image1,
  default as Section6Image2,
} from "../../../../../images/Course1/image2.png";
import Section6Image3 from "../../../../../images/Course1/pexels-photo-1194713.jpeg";
import Section2Image3 from "../../../../../images/Course1/section2_1.jpg";
import Section2Image1 from "../../../../../images/Course1/section2_3.jpg";
import Section2Image2 from "../../../../../images/Course1/section2_4_1024x1.webp";
// import Section2Image2 from "../../../../../images/Course1/section2_4_1024x1.jpg";
import Section5Image6 from "../../../../../images/Course1/tools/tools-aircrack-ng.png";
import Section5Image3 from "../../../../../images/Course1/tools/tools-burp_suite.png";
import Section5Image1 from "../../../../../images/Course1/tools/tools-kali-linux.png";
import Section5Image5 from "../../../../../images/Course1/tools/tools-metasploit.png";
import Section5Image7 from "../../../../../images/Course1/tools/tools-netcat.png";
import Section5Image2 from "../../../../../images/Course1/tools/tools-nmap.png";
import Section5Image4 from "../../../../../images/Course1/tools/tools-wireshark.png";
import Section1Image1 from "../../../../../images/Icons/icon1.png";
import Section3Image2 from "../../../../../images/Icons/image24.png";
import Section3Image1 from "../../../../../images/Icons/image25.png";
import Section3Image3 from "../../../../../images/Icons/image26.png";

// Практическая подготовка в

export const dataSectionHero = {
  title: "Этичный хакер - Специалист по Кибербезопасности",
  subtitle:
    "Курс по кибербезопасности и тестированию на проникновение (пентест)",
  courseTitle: "Курсы, которые мы предлагаем",
  description: "Погрузись в удивительный мир информациионной безопасности",
  buttons: [{ title: "Записаться на курс" }],
};

export const dataSection1 = {
  title: "Что точно узнаете на курсе",
  subtitle: "",
  description: "",
  image: {
    src: Section1Image2,
    alt: "Этичный хакер - специалист по кибербезопасности",
    title: "Этичный хакер - специалист по кибербезопасности",
  },
  details: [
    {
      title: "Тестирование на проникновение (пентест)",
      description:
        "Оценка информационной безопасности при помощи моделирования информационных атак",
      image: (
        <img
          src={Section1Image1}
          alt="Курс «Этичный хакер» | Онлайн-школа программирования IT-Security School"
        />
      ),
    },
    {
      title: "Принципы защиты сетей WiFi",
      description:
        "Изучение инструментов для взлома и атак на беспроводные WiFi-сети и принципы реализации срытых атак",
      image: (
        <img
          src={Section1Image1}
          alt="Курс «Этичный хакер» | Онлайн-школа программирования IT-Security School"
        />
      ),
    },
    {
      title: "Принципы защиты веб-приложений",
      description:
        "Безопасность и защита веб-приложений, основные типы атак и эксплуатация уязвимостей веб-приложений",
      image: (
        <img
          src={Section1Image1}
          alt="Курс «Этичный хакер» | Онлайн-школа программирования IT-Security School"
        />
      ),
    },
    {
      title: "Реверс-инжиниринг программ",
      description:
        "Концепции программирования, компиляции и декомпиляции программ",
      image: (
        <img
          src={Section1Image1}
          alt="Курс «Этичный хакер» | Онлайн-школа программирования IT-Security School"
        />
      ),
    },
  ],
};

export const dataSection2 = {
  title: "Кому подойдет курс",
  subtitle: "",
  description: "",
  image: null,
  alt: "",
  details: [
    {
      title: "Новичкам",
      subtitle: "",
      description:
        "Которые не имеют опыта в этичном хакинге, но хотят научиться. \nСпециалист по пентесту — отличный выбор для старта в IT. Вам не нужны специальные знания. Наш онлайн-курс даст сильный фундамент и  необходимые навыки, чтобы начать зарабатывать на пентесте.",
      image: {
        src: Section2Image1,
        alt: "",
        title: "Основы кибербезопасность и этичный хакинга",
      },
      dataAOS: "fade-right",
    },
    {
      title: "Тестировщикам",
      subtitle: "",
      description:
        "Которые хотят увеличить свой доход, предлагая услуги тестирования на проникновения и проверяя защищенность информационных систем.\nОсвоите пентест и научитесь находить и предвосхищать уязвимые места в различных digital-системах.",
      image: {
        src: Section2Image2,
        alt: "",
        title: "Основы кибербезопасность и этичный хакинга",
      },
      dataAOS: "fade-left",
    },
    {
      title: "Системным администраторам",
      subtitle: "",
      description:
        "Которые хотят систематизировать и углубить свои экспертные знания в области информационной безопасности.\n Прокачаете навыки в борьбе с кибератаками — и повысите свою экспертность и ценность как специалиста.",
      image: {
        src: Section2Image3,
        alt: "",
        title: "Основы кибербезопасность и этичный хакинга",
      },
      dataAOS: "fade-right",
    },
  ],
};

export const dataSection3 = {
  title: "Этот курс для Вас если",
  subtitle: "",
  description: "Что будет интересного на курсе",
  image: null,
  alt: "",
  details: [
    {
      title: "Интересуетесь программированием",
      subtitle: "",
      description:
        "Вы поймёте архитектуру современных сайтов, узнаете, какие существуют проблемы безопасности, и научитесь их решать",
      image: Section3Image1,
      alt: "Курс «Этичный хакер» | Онлайн-школа программирования IT-Security School",
    },
    {
      title: "Хотите атаковать и защищать сайты",
      subtitle: "",
      description:
        "Будете на законных основаниях взламывать сайты и получать за это достойную зарплату",
      image: Section3Image2,
      alt: "Курс «Этичный хакер» | Онлайн-школа программирования IT-Security School",
    },
    {
      title: "Любите решать нестандартные задачи",
      subtitle: "",
      description:
        "Сможете первыми узнавать о новых киберугрозах и бороться с ними",
      image: Section3Image3,
      alt: "Курс «Этичный хакер» | Онлайн-школа программирования IT-Security School",
    },
  ],
};

export const dataSection4 = {
  title: "Что входит в стоимость участия",
  subtitle: "",
  description: "Что входит в стоимость участия",
  image: null,
  alt: "",
  details: [
    {
      title: "",
      subtitle: "",
      description:
        "Базовый курс из 4-х тем, формирующий мышление специалиста по кибербезопасности",
      image: <RiCheckDoubleLine />,
      alt: "",
      dataAOS: "fade-right",
      dataAOSDelay: 0,
    },
    {
      title: "",
      subtitle: "",
      description:
        "4 практические задания по внедрению основ кибербезопасности и защиты данных в собственную жизнь",
      image: <RiCheckDoubleLine />,
      alt: "",
      dataAOS: "fade-left",
      dataAOSDelay: 0,
    },
    {
      title: "",
      subtitle: "",
      description:
        "Рейтинг достижений в учебном процессе с розыгрышем ценных призов",
      image: <RiCheckDoubleLine />,
      alt: "",
      dataAOS: "fade-left",
      dataAOSDelay: 200,
    },
    {
      title: "",
      subtitle: "",
      description:
        "Доступ к виртуальной лаборатории и все необходимое для выполнения практических домашних заданий",
      image: <RiCheckDoubleLine />,
      alt: "",
      dataAOS: "fade-right",
      dataAOSDelay: 200,
    },
    {
      title: "",
      subtitle: "",
      description:
        "Командный тренинг-игра для повышения самоорганизации, мотивации, получения навыков коммуникации и лидерства",
      image: <RiCheckDoubleLine />,
      alt: "",
      dataAOS: "fade-right",
      dataAOSDelay: 200,
    },
    {
      title: "",
      subtitle: "",
      description:
        "Закрытая группа участников курса с возможностью задавать вопросы инструктору и кураторам курса",
      image: <RiCheckDoubleLine />,
      alt: "",
      dataAOS: "fade-right",
      dataAOSDelay: 200,
    },
  ],
};

export const dataSection5 = {
  title: "Научитесь использовать",
  subtitle: "",
  description: "нструменты, которыми научитесь пользоваться",
  image: null,
  alt: "",
  details: [],
};

export const dataSection5Tools = [
  {
    title: "Kali Linux",
    image: Section5Image1,
    description: "",
    link: "/",
  },
  {
    title: "NMap",
    image: Section5Image2,
    description: "",
    link: "/",
  },
  {
    title: "Burp Suite",
    image: Section5Image3,
    description: "",
    link: "/",
  },
  {
    title: "Wireshark",
    image: Section5Image4,
    description: "",
    link: "/",
  },
  {
    title: "Metasploit Framework",
    image: Section5Image5,
    description: "",
    link: "/",
  },
  {
    title: "Aircrack-ng",
    image: Section5Image6,
    description: "",
    link: "/",
  },
  {
    title: "Netcat",
    image: Section5Image7,
    description: "",
    link: "/",
  },
];

export const dataProgram = {
  title: "Программа курса",
  subtitle: "",
  description:
    "Вас ждут онлайн-лекции и практические задания основанные на реальных кейсах",
  details: [
    {
      title: "Введение в пентест",
      details: [
        "Базовое ПО для пентеста: ОС",
        "Создание стенда для тестирования и его настройка",
        "Площадки для тестирования на проникновение в интернете",
      ],
    },
    {
      title: "Программирование и написание скриптов",
      details: [
        "Базовые понятия Python. Функции, переменные",
        "Пишем брутфорсер и кейлоггер на Python",
        "HTML и CSS. Основы JavaScript",
        "Сканирование баз данных. Атаки на базы данных. SQLi",
      ],
    },
    {
      title: "Тестирование на проникновение",
      details: [
        "Методология и ПО для web-pentest",
        "Применение BurpSuite",
        "Уязвимости клиентской части: XSS, CSRF, CSP",
        "Проведение client side-атак в вебе",
      ],
    },
    {
      title: "Операционные системы",
      details: [
        "Введение в администрирование и архитектуру Linux",
        "Сетевые сервисы. Принципы построения сетей, фильтрация трафика, маршрутизация",
        "Аудит безопасности в OS Linux. Сбор логoв и информации",
        "Введение в администрирование и архитектуру Windows",
        "Active Directory. Аутентификация и сбор данных",
        "Powershell",
      ],
    },
    {
      title: "Тестирование на проникновение сетей",
      details: [
        "Основы сетевого взаимодействия TCP/IP. Основные сетевые протоколы",
        "Исследование сетевого трафика. Средства обнаружения вторжения и утечек данных",
        "Атаки на беспроводные сети. Методология, оборудование",
        "Атаки на WPS, перехват handshake. Меры по противодействию атакам",
      ],
    },
    {
      title: "Итоговая дипломная работа",
      details: [
        "Итоговая дипломная работа",
        "Проведение итоговой командной игры",
      ],
    },
  ],
};

export const dataCountUp = {
  title: "",
  subtitle: "",
  description: "",
  details: [
    { id: 1, number: "60+", duration: "2", label: "часов учебных материалов" },
    { id: 1, number: "100", duration: "2", label: "практических заданий" },
    { id: 1, number: "22", duration: "2", label: "лабоработных работ" },
    { id: 1, number: "40", duration: "2", label: "инструментов для пентеста" },
  ],
};
/*

Программа разделена на 8 модулей и рассчитана на 5 месяцев. Студентов курса ждёт много практических занятий и домашних заданий с обратной связью от преподавателя. Для моделирования сценариев атаки и защиты школа предоставит доступ к платформе CyWar.

Занятия проведут практики с кейсами в «Тинькофф Банк», «Альфа-Банк», Positive Technologies, IBM, BI. ZONE и Insolar.

После прохождения курса студенты получат:

навыки управления требованиями ИБ в проектах, а также проведения внешних и внутренних пентестов;
диплом о профессиональной переподготовке установленного образца;
карьерные консультации с ИБ-экспертами и HR-специалистами;
возможность найти новую работу.

*/

export const dataSection6 = {
  title: "Как проходит обучение?",
  subtitle: "",
  description: "Что вас ждет во время обучения",
  image: null,
  alt: "",
  details: [
    {
      title: "",
      subtitle: "",
      description:
        "Программа разделена на несколько модулей. Студентов курса ждёт много практических занятий с обратной связью от преподавателей.",
      image: Section6Image1,
      imagePNG: Section6Image2,
      alt: "",
      detailsTitle: "После прохождения курса Вы получите:",
      details: [
        "Навыки проведения внешних и внутренних пентестов",
        "Возможность найти новую работу в качестве Специалист по информационной безопасности или Специалист по анализу защищённости",
        "Незабываемый опыт камандного взаимодействия и решения нестандартных задач",
      ],
    },
    {
      title: "Инсценеровка проникновения (PENTEST)",
      subtitle: "",
      description:
        "Вы объединяетесь в команды и будете получать баллы за каждое выполненное задание.\nУ Вас будет уникальная возможность смоделировать действий злоумышленника при совершении атаки на информационные системы и их дальнейшей эксплуатации.",
      image: Section6Image3,
      imagePNG: Section6Image3,
      alt: "",
      detailsTitle: "Такой формат позволяет",
      details: [
        "Сделать из курса не просто набор полезных видео, а ваш будущий результат",
        "Получить навык выявленных уязвимостей и построению системы защиты",
      ],
    },
  ],
};

export const dataSection7 = {
  title: "Какие типы взломов и атак научитесь предотвращать",
  subtitle: "",
  description: "Какие типы взломов и атак научитесь предотвращать",
  image: null,
  alt: "",
  details: [
    {
      title: "Атаки на сайты",
      subtitle: "",
      description:
        "Находить и устранять уязвимости на веб-сайтах и веб-серверах",
      image: null,
      imagePNG: null,
      alt: "",
    },
    {
      title: "Атаки на сети",
      subtitle: "",
      description:
        "Навык проведения атак на беспроводные WiFi-сети, анализ и расшифровки перехваченного трафика и запросов",
      image: null,
      imagePNG: null,
      alt: "",
    },
    {
      title: "Атаки на операционные системы",
      subtitle: "",
      description:
        "Аудитом безопасности ОС Linux, Windows и Active Directory, сбором и анализ информации",
      image: null,
      imagePNG: null,
      alt: "",
    },
    {
      title: "Атаки на корпоративные системы",
      subtitle: "",
      description:
        "Методики сбора информации, атаки на антивирусные приложения основные утилиты для провеедения пентеста",
      image: null,
      imagePNG: null,
      alt: "",
    },
  ],
};

export const dataFAQ = {
  title: "Часто задаваемые вопросы (F.A.Q)",
  subtitle: "",
  description: "Часто задаваемые вопросы",
  details: [
    {
      title: "Я никогда не занимался кибербезопасностью. У меня получится?",
      description:
        "Курс построен вокруг практики и включает только необходимый минимум теории. С первого дня вы начнете решать задачи, над которыми работают разработчики в реальных компаниях. \nЕженедельно вам будет открываться доступ к очередному модулю, который содержит материалы для освоения и кейсы для решения на ближайшие 7 дней. Материалы — это в первую очередь практические задачи по написанию кода, а также видеолекции, скринкасты, заготовки кода и статьи.",
    },
    {
      title: "Какая длительность курса?",
      description:
        "Длительность курса 3 месяца. За это время Вы пройдете основные теоретические блоки, сможете выполнить лабораторные работы и подготовится к итоговому экзамену.",
    },
    {
      title: "Когда стартует ближайший курс?",
      description:
        "Мы стараемся делать запуски как только набираем минимальную группу 6-8 человек. Ближайщий старт группы запланирован на октябрь. Вы можете приобрести запись отдельно от курса, но это будет ни так интересно как командная работа над взломом.",
    },
    {
      title: "Сколько часов в неделю нужно будет уделять учёбе?",
      description:
        "Всё зависит только от вас. В среднем наши студенты занимаются от трёх до пяти часов в неделю.",
    },
    {
      title: "Я смогу общаться с преподавателями?",
      description:
        "Да, у вас будет доступ к закрытому Telegram-чату. Команда преподавателей будет комментировать домашние задания и давать полезные советы.",
    },
    {
      title: "Какое оборудование необходимо для прохождения курса?",
      description:
        "Только компьютер подключенный к сети Интернет. Для моделирования сценариев атаки и защиты будет предоставлен удаленный доступ к виртуальной лаборатории. Мы поможем с нуля освоить передовые технологии и приобрести необходимые навыки.",
    },
    {
      title: "Действуют ли какие-нибудь программы рассрочки?",
      description:
        "Да, вы можете купить курс в рассрочку — и спланировать свой бюджет, разбив всю сумму на мелкие ежемесячные платежи.",
    },
  ],
};

export const dataPrice = {
  title: "Запишитесь или получите консультацию",
  subtitle: "",
  description: "Запишитесь или получите консультацию",
  details: [
    {
      title: "86 000 ₽",
      subtitle: "7 166 ₽/мес",
      description: "Стоимость курса",
    },
  ],
};
