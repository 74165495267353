import React, { Fragment, useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import ContactShortForm from "./ContactShormForm";
import { SendFeedback } from "./Utils";

const successMessage = "В ближайшее время наш менеджер свяжется с вами!";
const errorMessage = "Your message did not Submitted!";

const ContactShort = (props) => {
  const { eventName } = props;
  const [status, setStatus] = useState("Init");
  const [submit, setSubmit] = useState(false);
  const [error, setError] = useState(null);
  //const { triggerYM, triggerGA } = useContext(Context);

  let history = useHistory();

  const handleSubmit = (e) => {
    e.preventDefault();

    setStatus("Sending...");

    const { fullname, email, phone } = e.target.elements;
    let details = {
      fullname: fullname.value,
      email: email.value,
      phone: phone.value,
    };

    // console.log(details);
    SendFeedback(details, setSubmit, setError);

    setStatus("Submit");
  };

  const sendAnalytics = () => {
    /*triggerYM("conversion", {
      send_to: "AW-306489721/l9IZCPjKtf0CEPnSkpIB",
    });
    triggerGA("conversion", "AW-306489721/l9IZCPjKtf0CEPnSkpIB");*/
    //triggerGA("form", "submit", "contact");
  };

  useEffect(() => {
    if (status === "Submit" && submit) {
      // Redirect on
      if (eventName) history.push(`/event/registration?course=${eventName}`);
      else history.push(`/event/registration`);
      // Analytics
      sendAnalytics();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, submit]);

  return (
    <Fragment>
      <ContactShortForm onSubmit={handleSubmit} />
      <div>
        {submit && (
          <div className="message-success">
            <p>{successMessage}</p>
          </div>
        )}
        {error && (
          <div className="message-error">
            <p>{errorMessage}</p>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default withRouter(ContactShort);
