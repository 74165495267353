import React from "react";
import { Col, Container, Nav, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { dataOwner } from "../../data/site";
import SocialLinks from "../sections/TypicalSections/Social/SocialLinks";
import { ExternalLink } from "../utils/Utils";

/**
             <a href="#header" className="scrollto footer-logo">
              <img src="assets/img/hero-logo.png" alt="" />
            </a>

 */

const data = {
  title: "IT-security school",
  subtitle: "IT-security school",
  description: "Качественное образование - доступное каждому",
};

const FooterMenu = () => {
  const bsPrefix="footer-menu-nav-link nav-link"
  return (
    <div className="footer-menu-navbar">
      <Nav className="footer-menu-navbar-nav">
        <Nav.Link href="/" bsPrefix={bsPrefix}>
          Home
        </Nav.Link>
        <Nav.Link href="/courses" bsPrefix={bsPrefix}>
          Курсы
        </Nav.Link>
        <Nav.Link href="/blog" bsPrefix={bsPrefix}>
          Блог
        </Nav.Link>
        <Nav.Link href="/contact" bsPrefix={bsPrefix}>
          Контакты
        </Nav.Link>
      </Nav>
    </div>
  );
};

const Footer = () => {
  return (
    <footer className="main-footer">
      <Container className="footer-top">
        <Row className="justify-content-center">
          <Col lg="6">
            <h2 className="footer-text"> {data.subtitle}</h2>
            <p className="footer-text">{data.description}</p>
            <p className="footer-text">
              <Link to="/offercontract">Договор оферты</Link>
            </p>
          </Col>
          <Col lg="6">
            <SocialLinks />
            <FooterMenu />
          </Col>
        </Row>
      </Container>
      <Container className="footer-bottom border-top border-smooth">
        <div className="copyright">
          © Copyright{" "}
          <strong>
            <span className="footer-text">{dataOwner.title}</span>
          </strong>
          . Все права защищены.{" "}
          <Link to="/privacy">Политика конфидециальности.</Link>
        </div>
        <div className="credits d-block">
          Designed by{" "}
          <ExternalLink to="https://lovkovav.pro/" label="LovkovAV.PRO" />
        </div>
      </Container>
    </footer>
  );
};

export default Footer;

/*
        <Container className="footer-top">
          <Row className="justify-content-center">
            <Col lg="6">
              <a href="#header" className="scrollto footer-logo">
                <img src="assets/img/hero-logo.png" alt="" />
              </a>
              <h3>Knight</h3>
              <p>
                Et aut eum quis fuga eos sunt ipsa nihil. Labore corporis magni
                eligendi fuga maxime saepe commodi placeat.
              </p>
            </Col>
            <Col lg="6">
              <SocialLinks />
            </Col>
          </Row>
        </Container>

        <Container className="footer-bottom">
          <div className="copyright">
            © Copyright{" "}
            <strong>
              <span>Knight</span>
            </strong>
            . All Rights Reserved
          </div>
          <div className="credits">
            Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a>
          </div>
        </Container>
         */
