import PropTypes from "prop-types";
import React from "react";
import { SiteSectionCourseProgram } from "../../../TypicalSections";
import { dataProgram as data } from "../data/course";

const Section = (props) => {
  const { courseName } = props;

  return <SiteSectionCourseProgram courseName={courseName} data={data} />;
};

Section.propTypes = {
  courseName: PropTypes.string.isRequired,
};

export default Section;
