import PropTypes from "prop-types";
import React from "react";
import { Carousel, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import useIsMobile from "../../../../../hooks/useIsMobile";
import PageSection from "../../../../utils/SiteSection/PageSection";
import ControlledCarousel from "../../../TypicalSections/ControlledCarousel/ControlledCarousel";
import {
  dataSection5 as data,
  dataSection5Tools as dataTools,
} from "../data/course";

const Section = (props) => {
  const { courseName, name } = props;
  // const onlyWidth = useWindowWidth();
  const isMobile = useIsMobile();
  const interval = 2000;

  const CaruselItem = ({ index }) => {
    //const URL = `https://via.placeholder.com/200x200.png?text=${index}`;
    //console.log(URL);

    return (
      <Col lg="3" className="carousel-item-box float-lg-start ">
        <div className="carousel-item-caption">
          <Link to={dataTools[index].link}>
            <h3>{dataTools[index].title}</h3>
          </Link>
        </div>
        <img
          src={dataTools[index].image}
          width="200"
          height="200"
          className="img-responsive"
          alt={dataTools[index].description}
        />
      </Col>
    );
  };

  const MobileCarusel = () => {
    return (
      <Col>
        <Carousel interval={interval}>
          <Carousel.Item>
            <CaruselItem index="0" />
          </Carousel.Item>
          <Carousel.Item>
            <CaruselItem index="1" />
          </Carousel.Item>
          <Carousel.Item>
            <CaruselItem index="2" />
          </Carousel.Item>
          <Carousel.Item>
            <CaruselItem index="3" />
          </Carousel.Item>
          <Carousel.Item>
            <CaruselItem index="4" />
          </Carousel.Item>
          <Carousel.Item>
            <CaruselItem index="5" />
          </Carousel.Item>
          <Carousel.Item>
            <CaruselItem index="6" />
          </Carousel.Item>
        </Carousel>
      </Col>
    );
  };

  const DesktopCarousel = () => {
    return (
      <Col>
        <ControlledCarousel className="carousel-controlled" interval={interval}>
          <Carousel.Item>
            <CaruselItem index="0" />
            <CaruselItem index="1" />
            <CaruselItem index="2" />
            <CaruselItem index="3" />
          </Carousel.Item>
          <Carousel.Item>
            <CaruselItem index="1" />
            <CaruselItem index="2" />
            <CaruselItem index="3" />
            <CaruselItem index="4" />
          </Carousel.Item>
          <Carousel.Item>
            <CaruselItem index="2" />
            <CaruselItem index="3" />
            <CaruselItem index="4" />
            <CaruselItem index="5" />
          </Carousel.Item>
          <Carousel.Item>
            <CaruselItem index="3" />
            <CaruselItem index="4" />
            <CaruselItem index="5" />
            <CaruselItem index="6" />
          </Carousel.Item>
          <Carousel.Item>
            <CaruselItem index="4" />
            <CaruselItem index="5" />
            <CaruselItem index="6" />
            <CaruselItem index="0" />
          </Carousel.Item>
          <Carousel.Item>
            <CaruselItem index="5" />
            <CaruselItem index="6" />
            <CaruselItem index="0" />
            <CaruselItem index="1" />
          </Carousel.Item>
          <Carousel.Item>
            <CaruselItem index="6" />
            <CaruselItem index="0" />
            <CaruselItem index="1" />
            <CaruselItem index="2" />
          </Carousel.Item>
        </ControlledCarousel>
      </Col>
    );
  };

  return (
    <PageSection
      courseName={courseName}
      name={name}
      data={data}
      cnt="section-feature"
    >
      <Row>{isMobile ? <MobileCarusel /> : <DesktopCarousel />}</Row>
    </PageSection>
  );
};

Section.propTypes = {
  courseName: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default Section;
