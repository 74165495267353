import React from "react";
import ReactDOM from "react-dom";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";

// https://giters.com/stereobooster/react-snap/issues/448

const rootElement = document.getElementById("root");
const hasChildNodes = rootElement.hasChildNodes();

const helmetContext = {};
// on the server
const context = {};

const AppHOC = () => {
  return (
    <HelmetProvider context={helmetContext}>
      <React.StrictMode>
        <Router basename="/">
          <App />
        </Router>
      </React.StrictMode>
    </HelmetProvider>
  );
};
//
if (hasChildNodes) {
  console.log("[server] render");
  ReactDOM.hydrate(<AppHOC />, rootElement);
} else {
  console.log("[client] render");
  ReactDOM.render(<AppHOC />, rootElement);
}

/*
getCLS(console.log, true);
getFID(console.log, true);
getLCP(console.log, true);
*/
